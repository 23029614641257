aside {
    border-right: #E5E5E5 solid 1px;
}

#sidebar {
    overflow: auto;
}

@media (max-width: 768px) {
    #sidebar {
        width: 265px;
    }
}

@media (min-width: 992px) {
    #sidebar {
        z-index: 999;
    }
}

#sidebar .aspect-link {
    cursor: pointer;
}

#sidebar .aspect-link:hover {
    text-decoration: underline;
}

#menu ul {
    list-style-type: none;
}