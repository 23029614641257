
html, body, #root {
    background: #ebeef1 !important;
    height: 100%;
    display: block;
    color: #242424;
}

.slideout-menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 256px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: none;
}

.slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
    overflow: hidden;
}

.slideout-open .slideout-menu {
    display: block;
}

.cursor-pointer:not(.disabled) {
    cursor: pointer
}

#modulosContainer optgroup::before {
    padding: 12px 5px 8px;
}
#login .cover {
    background-size: cover;
}
aside {
    border-right: #E5E5E5 solid 1px;
}

#sidebar {
    overflow: auto;
}

@media (max-width: 768px) {
    #sidebar {
        width: 265px;
    }
}

@media (min-width: 992px) {
    #sidebar {
        z-index: 999;
    }
}

#sidebar .aspect-link {
    cursor: pointer;
}

#sidebar .aspect-link:hover {
    text-decoration: underline;
}

#menu ul {
    list-style-type: none;
}
#header-restrict-area {
    border-bottom: #E5E5E5 solid 1px;
    z-index: 997;
}

#header-restrict-area .notifies {
    width: 300px;
    max-height: 350px;
    overflow: auto;
}

#header-restrict-area .notify {
    display: block;
    font-size: 11px;
    font-size: 0.61111rem;
    position: absolute;
    margin-top: -18px;
    margin-left: 8px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

#header-restrict-area .dropdown-menu {
    min-width: 250px;
}

@media (max-width: 768px) {
    #header-restrict-area .notify {
        font-size: 15px !important;
    }
}

@media (max-width: 768px) {
    #header-restrict-area .notify {
        margin-top: -43px !important;
    }
}

@media (max-width: 768px) {
    #header-restrict-area .notify {
        margin-left: 12px !important;
    }
}

@media (max-width: 768px) {
    #header-restrict-area .notify {
        width: 23px !important;
    }
}

@media (max-width: 768px) {
    #header-restrict-area .notify {
        height: 23px !important;
    }
}

#header-restrict-area .top-items {
    list-style-type: none;
}

#header-restrict-area .top-items li {
    display: inline-block;
}

#header-restrict-area .top-items a {
    text-decoration: none;
}

#header-restrict-area .nicename {
    font-size: 14px;
    font-size: 0.77778rem;
}

#header-restrict-area .profile-image {
    background-color: #6B98B7;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
#slider .slide {
    height: 300px;
}

#slider {
    position: relative;
}

#slider .slick-prev {
    position: absolute;
    left: 2.5%;
    z-index: 2;
}

#slider .slick-prev::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\F104';
    font-size: 32px;
}

#slider .slick-next {
    position: absolute;
    right: 2.5%;
    z-index: 2;
}

#slider .slick-next::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\F105';
    font-size: 32px;
}

#slider .slick-dots {
    bottom: -30px;
}

#slider .slick-dots li {
    margin: 0 2px;
    opacity: 1;
    height: 15px;
    width: 15px;
}

#slider .slick-dots li button {
    opacity: 1;
    height: 15px;
    width: 15px;
    padding: 0;
}

#slider .slick-dots li button::before {
    background-color: #F6F7FB;
    border-radius: 50%;
    content: "";
    opacity: 1;
    font-size: 12px;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#slider .slick-dots li.slick-active button {
    opacity: 1;
}

#slider .slick-dots li.slick-active button::before {
    background-color: #fff;
    opacity: 1;
}
#search-area .search {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: none !important;
}

#search-area .submit {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
#last-activities .item {
    border: #E5E5E5 solid 1px;
    border-radius: 5px !important;
    text-decoration: none;
}

#last-activities .item:hover {
    text-decoration: none;
    background-color: #f3f3f3;
}

#last-activities .item .ballon-new {
    position: absolute;
    right: 0;
    margin-top: -23px;
    font-size: 12px;
    font-size: 0.66667rem;
}

@media (max-width: 576px) {
    #last-activities .item .ballon-new {
        right: 0;
    }
}

@media (max-width: 576px) {
    #last-activities .item .ballon-new {
        top: 21px;
    }
}

#last-activities .item .ballon-new.bg-success .arrow-right {
    margin-top: 0px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #30E360;
    position: absolute;
    left: 0;
    z-index: 1;
}

#last-activities .item .ballon-new.bg-danger .arrow-right {
    margin-top: 0px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #DA0015;
    position: absolute;
    left: 0;
    z-index: 1;
}

#last-activities .title {
    font-size: 16px;
    font-size: 0.88889rem;
}

#last-activities .teacher {
    font-size: 13px;
    font-size: 0.72222rem;
}

#last-activities .start {
    font-size: 15px;
    font-size: 0.83333rem;
}

#last-activities .more-activities {
    text-transform: uppercase;
    font-size: 16px;
    font-size: 0.88889rem;
}
#activity-list .notification {
    font-size: 12px;
    font-size: 0.66667rem;
    position: absolute;
    right: 0;
    top: -10px;
}

#activity-list .card-activity-student {
    text-decoration: none;
}

#activity-list .card-activity-student:hover {
    text-decoration: none;
}

#activity-list .card-activity-student .card-body {
    position: relative;
}

#activity-list .new-activity .arrow-right {
    margin-top: 7px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #30e360;
    position: absolute;
    left: 0;
    z-index: 1;
}

#activity-list .activity-in-atention .arrow-right {
    margin-top: 7px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #da0015;
    position: absolute;
    left: 0;
    z-index: 1;
}

#activity-list .card-activity div {
    border: #E5E5E5 solid 1px;
}

#activity-list h3 {
    font-size: 16px;
    font-size: 0.88889rem;
}

#activity-list .discipline {
    font-size: 13px;
    font-size: 0.72222rem;
}

#activity-list p {
    margin-bottom: 0px;
}

#activity-list .info {
    font-size: 15px;
    font-size: 0.83333rem;
}

#activity-list .access-activity {
    font-size: 16px;
    font-size: 0.88889rem;
}
#shortcuts .btn-sc-activities, #shortcuts .btn-sc-files, #shortcuts .btn-sc-tutorials {
    border-radius: 5px !important;
    text-decoration: none;
}

#shortcuts .btn-sc-activities h1, #shortcuts .btn-sc-files h1, #shortcuts .btn-sc-tutorials h1 {
    font-size: 30px;
    font-size: 1.66667rem;
    font-weight: 900;
}

#shortcuts .btn-sc-activities p, #shortcuts .btn-sc-files p, #shortcuts .btn-sc-tutorials p {
    font-size: 16px;
    font-size: 0.88889rem;
}
.react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
    /*display: none;*/
}

.react-calendar .react-calendar__navigation .react-calendar__navigation__label,
.react-calendar .react-calendar__navigation .react-calendar__navigation__arrow{
    font-size: 2rem;
    margin-bottom: 2rem;
    border: 1px solid #dedede;
    padding: 1rem;
}

.react-calendar .react-calendar__tile {
    height: 5rem;
}

.react-calendar .react-calendar__month-view__weekdays__weekday {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
}

.react-calendar .react-calendar__tile--active {
    background: #d2d2d2;
    /*color: white;*/
}

.react-calendar .react-calendar__tile--active:enabled:hover,
.react-calendar .react-calendar__tile--active:enabled:focus {
    background: #d2d2d2;
    /*color: white;*/
}

.react-calendar .react-calendar__month-view__days__day--neighboringMonth {
    background: #929292;
    color: white;
}

.react-calendar .react-calendar__tile--now {
    background: #37c1d5;
    color: white;
}

.react-calendar .react-calendar__tile,
.react-calendar .react-calendar__month-view__days__day {
    font-size: 1rem;
}

#embed-live .open-zoom {
    position: absolute;
    right: 20px;
    top: -20px;
    font-size: 22px;
    font-size: 1.22222rem;
    z-index: 996;
    text-decoration: none;
}


#embed-live .open-zoom .arrow-right {
    margin-top: 20px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #30e360;
    position: absolute;
    left: 0;
}

#embed-live .open-zoom:hover {
    text-decoration: none;
    -webkit-filter: brightness(60%);
            filter: brightness(60%);
}

#embed-live .video {
    min-height: 390px;
    position: relative;
}
#header-activity h1 {
    font-size: 18px;
    font-size: 1rem;
    text-transform: uppercase;
}

#header-activity h2, #header-activity p {
    font-size: 16px;
    font-size: 0.88889rem;
    text-transform: uppercase;
}

#header-activity p {
    margin-bottom: 0px;
}


#sidebar-right h2 {
    font-size: 18px;
    font-size: 1rem;
}

#sidebar-right .label-answered, #sidebar-right .label-no-answered {
    display: block;
    width: 15px;
    height: 15px;
    float: left;
}

#sidebar-right .label-answered {
    background-color: #99D892;
}

#sidebar-right .label-no-answered {
    background-color: #6B98B7;
}

#sidebar-right #labels-activities p {
    font-size: 14px;
    font-size: 0.77778rem;
    margin-bottom: 0px;
}

#sidebar-right #labels-activities .labels {
    font-size: 15px;
    font-size: 0.83333rem;
}

#sidebar-right #prev-question:hover {
    text-decoration: none;
    background: #f6f6f6 !important;
}

#sidebar-right #prev-question p {
    font-size: 14px;
    font-size: 0.77778rem;
    margin-bottom: 0px;
}

#sidebar-right .far {
    color: #6B98B7;
}

#sidebar-right #prev-question {
    border: #6B98B7 solid 1px !important;
}

#sidebar-right .fas {
    color: #6B98B7;
}

#sidebar-right #prev-question.already-answered {
    border: #99D892 solid 1px !important;
}

#sidebar-right #prev-question.current {
    border: #343a40 dashed 1px !important;
}

#sidebar-right #prev-question.already-answered .fas {
    color: #99D892;
}
#questions h1 {
    font-size: 18px;
    font-size: 1rem;
    text-transform: uppercase;
}

#questions p, #questions li, #questions textarea {
    font-size: 16px;
    font-size: 0.88889rem;
}

#questions .indicator, #questions .btn {
    font-size: 18px;
    font-size: 1rem;
}

#questions .alternative-answer ul {
    list-style-type: none;
}

#questions .alternative-answer li {
    cursor: pointer;
    transition-duration: 0.2s;
}

#questions .alternative-answer li:hover {
    background-color: #6B98B7 !important;
    color: #fff !important;
}

#questions .alternative-answer li.selected {
    background-color: #6B98B7 !important;
    color: #fff !important;
}

#questions .alternative-answer li .circle {
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: #E5E5E5 solid 1px;
}

#questions .alternative-answer li.selected .circle {
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #2c5f82;
    border: #105D93 solid 1px;
}
#type-of-activities ul {
    list-style-type: none;
}

#type-of-activities ul li {
    display: inline-block;
    font-size: 12px;
    font-size: 0.66667rem;
    cursor: pointer;
}

#type-of-activities ul .active {
    background-color: #6B98B7 !important;
}

#type-of-activities ul .active a {
    color: #fff !important;
}
.react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
    /*display: none;*/
}

.react-calendar .react-calendar__navigation .react-calendar__navigation__label,
.react-calendar .react-calendar__navigation .react-calendar__navigation__arrow{
    font-size: 2rem;
    margin-bottom: 2rem;
    border: 1px solid #dedede;
    padding: 1rem;
}

.react-calendar .react-calendar__tile {
    height: 5rem;
}

.react-calendar .react-calendar__month-view__weekdays__weekday {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
}

.react-calendar .react-calendar__tile--active {
    background: #d2d2d2;
    /*color: white;*/
}

.react-calendar .react-calendar__tile--active:enabled:hover,
.react-calendar .react-calendar__tile--active:enabled:focus {
    background: #d2d2d2;
    /*color: white;*/
}

.react-calendar .react-calendar__month-view__days__day--neighboringMonth {
    background: #929292;
    color: white;
}

.react-calendar .react-calendar__tile--now {
    background: #37c1d5;
    color: white;
}

.react-calendar .react-calendar__tile,
.react-calendar .react-calendar__month-view__days__day {
    font-size: 1rem;
}

